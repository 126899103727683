
































































import Vue from 'vue';
import IconSearch from '@/components/specific/IconSearch.vue';
import { UICommonMapper } from '@/store/modules/ui/common';
import type { User } from '@/store/modules/ui/common';

export default Vue.extend({
  name: 'MemberListPopup',

  components: {
    IconSearch,
  },

  data(): {
    filteredUserList: User[];
    indexes: string[];
    searchText: string;
  } {
    return {
      filteredUserList: [],
      indexes: ['あ', 'か', 'さ', 'た', 'な', 'は', 'ま', 'や', 'ら', 'わ', 'A'],
      searchText: '',
    };
  },

  computed: {
    ...UICommonMapper.mapState(['userList', 'popupUserId']),
  },

  created() {
    this.filteredUserList = this._.cloneDeep(this.userList);
    this.addKanaIndex();
  },

  methods: {
    ...UICommonMapper.mapActions([
      'hidePopupBackgroundLayer',
      'setMessage',
      'clearPopupUserId',
      'hideMemberPopup',
      'setPopupUserId',
      'showMemberPopup',
      'hideMemberPopup',
    ]),

    addKanaIndex() {
      const self = this;
      let lastKanaIndex = '';

      self._.forEach(self.filteredUserList, (user) => {
        const kanaIndex = !self._.isEmpty(user.kana) ? user.kana.slice(0, 1) : '';
        // ひらがなへ変換
        const kana = kanaIndex.replace(/[\u30A1-\u30FA]/g, (ch) =>
          String.fromCharCode(ch.charCodeAt(0) - 0x60)
        );

        if (lastKanaIndex !== kana) {
          self._.set(user, 'index', kana);
          lastKanaIndex = kana;
        }
      });
    },

    closePopup() {
      this.$emit('click-close');
      this.hidePopupBackgroundLayer();
    },

    filter() {
      if (this._.isEmpty(this.searchText)) {
        this.filteredUserList = this.userList;
      } else {
        const searchTextKana = this.searchText.replace(/[\u3041-\u3096]/g, (ch) =>
          String.fromCharCode(ch.charCodeAt(0) + 0x60)
        );
        const filtered = this.userList.filter((v: User) => {
          return (
            v.kana?.includes(searchTextKana) ||
            v.kana?.includes(this.searchText) ||
            v.userName?.includes(this.searchText)
          );
        });
        this.filteredUserList = filtered;
      }
    },

    // 選択ユーザーのactive-classを有効にする
    itemSelected(userId: string): boolean {
      return userId === this.popupUserId;
    },

    scrollToTheMember(kana: string) {
      const self = this;
      const index = self._.findIndex(self.filteredUserList, { index: kana });

      if (index >= 0) {
        const $el = this._.get(this.$refs.scroll, '$el');
        const scrollHeight = 64 * index;
        $el.scrollTop = scrollHeight;
      }
    },

    toggleMemberPopup(user: User) {
      // 選択を解除する
      if (this.popupUserId === user.userId) {
        this.clearPopupUserId();
        this.hideMemberPopup();
      } else {
        this.setPopupUserId({ userId: user.userId });
        this.showMemberPopup();
      }
    },

    userNameShorten(user: User): string {
      // TODO 実際には必ず名前は入っているので、このコードはリリース前に修正する
      return this._.get(user, 'userName', '不明').substring(0, 2);
    },
  },
});
