







































































































import Vue from 'vue';
import IconCloseMini from '@/components/specific/IconCloseMini.vue';
import IconHash from '@/components/specific/IconHash.vue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIMemberFilterMapper } from '@/store/modules/ui/memberFilter';
import type {
  FilterGroupName,
  FilterItem,
  FilterConditionPayload,
} from '@/store/modules/ui/memberFilter';

const ProjectService = ServiceFactory.get('project');
const DepartmentService = ServiceFactory.get('department');
const GroupService = ServiceFactory.get('group');

type SelectedFilter = {
  type: string;
  id: string;
  name: string;
};

export default Vue.extend({
  name: 'FilterPopup',

  components: {
    IconCloseMini,
    IconHash,
  },

  data(): {
    filterItemsLoaded: boolean;
    localFilterItems: {
      projects: FilterItem[];
      departments: FilterItem[];
      groups: FilterItem[];
    };
    filterTabs: {
      text: string;
      value: string;
    }[];
    selectedTab: FilterGroupName;
  } {
    return {
      filterItemsLoaded: false,
      filterTabs: [
        {
          text: 'プロジェクト',
          value: 'projects',
        },
        {
          text: '部署',
          value: 'departments',
        },
        {
          text: 'グループ',
          value: 'groups',
        },
      ],
      localFilterItems: {
        departments: [],
        groups: [],
        projects: [],
      },
      selectedTab: 'projects',
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UIMemberFilterMapper.mapState(['filterItems', 'filtered']),
    ...UICommonMapper.mapState(['showedFilterPopup']),

    filterCount(): number {
      if (this.selectedTab === 'projects') {
        return this.filterItems[this.selectedTab].length - 1;
      }
      return this.filterItems[this.selectedTab].length;
    },

    selectedFilters(): SelectedFilter[] {
      const selected: SelectedFilter[] = [];
      this._.forEachRight(this.localFilterItems, (filterGroup, filterGroupName) => {
        this._.forEach(filterGroup, (item) => {
          if (item.selected) {
            selected.push({
              id: item.id,
              name: item.name,
              type: filterGroupName,
            });
          }
        });
      });

      return selected;
    },
  },

  watch: {
    filterItems: {
      handler() {
        this.syncLocalFilterItems();
      },
      deep: true,
    },

    showedFilterPopup: {
      handler() {
        if (this.showedFilterPopup) {
          this.syncLocalFilterItems();
        }
      },
    },
  },

  async created() {
    const self = this;

    // フィルター適用したことがあればストアから取得
    if (self.filtered) {
      self.localFilterItems = self._.cloneDeep(self.filterItems);
      return;
    }

    const { userId, workspaceId } = self.userAttributes;

    const procedures = [
      ProjectService.getProjectList(workspaceId),
      DepartmentService.getDepartmentList(workspaceId),
      GroupService.getGroupList(workspaceId, userId),
    ];

    try {
      const [responseProject, responseDepartment, responseGroup] = await Promise.all(procedures);

      self._.forEach(responseProject, (item) => {
        self.localFilterItems.projects.push({
          // 定義の順で記述
          /* eslint-disable vue/sort-keys */
          id: item.projectId,
          name: item.projectName,
          selected: false,
          applied: false,
          /* eslint-enable vue/sort-keys */
        });
      });
      self._.forEach(responseDepartment, (item) => {
        self.localFilterItems.departments.push({
          // 定義の順で記述
          /* eslint-disable vue/sort-keys */
          id: item.departmentId,
          name: item.departmentName,
          selected: false,
          applied: false,
          /* eslint-enable vue/sort-keys */
        });
      });
      self._.forEach(responseGroup, (item) => {
        self.localFilterItems.groups.push({
          // 定義の順で記述
          /* eslint-disable vue/sort-keys */
          id: item.groupId,
          name: item.groupName,
          selected: false,
          applied: false,
          /* eslint-enable vue/sort-keys */
        });
      });

      self.setFilterItems(self.localFilterItems);
      self.filterItemsLoaded = true;
    } catch (error: any) {
      self.$$log.error(error);
      self.setMessage({ color: 'error', text: error.message });
    }
  },

  methods: {
    ...UICommonMapper.mapActions(['setMessage', 'hidePopupBackgroundLayer']),
    ...UIMemberFilterMapper.mapActions([
      'setCondition',
      'clearCondition',
      'setFilterItems',
      'setFiltered',
    ]),

    applyFilter() {
      const self = this;
      const payload: FilterConditionPayload = {
        departments: {
          ids: [],
        },
        groups: {
          ids: [],
        },
        projects: {
          ids: [],
        },
      };

      // 選択フィルターがなければメインプロジェクトを追加
      const mainProject = self._.find(self.localFilterItems.projects, { id: 'main' });
      if (self.selectedFilters.length === 0) {
        if (mainProject) {
          mainProject.selected = true;
        }
      }
      // メインプロジェクト以外の選択フィルターがあればメインプロジェクトを除外
      else if (self.selectedFilters.length > 1) {
        if (mainProject) {
          mainProject.selected = false;
        }
      }

      self._.forEach(self.selectedFilters, (filter) => {
        if (filter.type === 'departments') {
          payload.departments.ids.push(filter.id);
        }
        if (filter.type === 'groups') {
          payload.groups.ids.push(filter.id);
        }
        if (filter.type === 'projects') {
          payload.projects.ids.push(filter.id);
        }
      });

      // 選択フィルターがメインプロジェクトだけならフィルターなし扱い
      const selectedMain = self._.find(self.selectedFilters, { id: 'main', type: 'projects' });
      const filtered = !(self.selectedFilters.length === 1 && selectedMain);
      self.setFiltered({ filtered });
      this.setFilterItems(this.localFilterItems);

      self.setCondition(payload);

      self.$emit('click-apply-filter');
    },

    clearFilter() {
      this._.each(this.localFilterItems, (items, key) => {
        const filterGroupName: FilterGroupName = key as FilterGroupName;
        this._.each(items, (item, index) => {
          this.localFilterItems[filterGroupName][index].selected = false;
        });
      });
      // メインプロジェクトのみ
      const mainProject = this._.find(this.localFilterItems.projects, { id: 'main' });
      if (mainProject) {
        mainProject.selected = true;
      }
    },

    closePopup() {
      this.$emit('click-close');
    },

    removeFilter(item: SelectedFilter) {
      const typedFilter = this._.get(this.localFilterItems, item.type);
      this._.find(typedFilter, { id: item.id }).selected = false;
    },

    selectTab(value: FilterGroupName) {
      this.selectedTab = value;
    },

    syncLocalFilterItems() {
      this.localFilterItems = this._.cloneDeep(this.filterItems);
    },
  },
});
