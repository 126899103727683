



































import Vue from 'vue';
import VueDraggableResizable from 'vue-draggable-resizable';
import UserStatus from '@/components/specific/UserStatus.vue';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIRelationshipMapper } from '@/store/modules/ui/relationship';
import type { Relationship } from '@/store/modules/ui/relationship';

// const ClientWidth = 296;
// const ClientHeight = 368;
// const OffsetLeft = 105;
// const OffsetTop = 22;
// const SideBarWidth = 68;

export default Vue.extend({
  name: 'FilterResult',

  components: {
    UserStatus,
    VueDraggableResizable,
  },

  data(): {
    dragParams: {
      marginRight: number;
      maxX: number;
      maxY: number;
    };
    // initial: {
    //   x: number;
    //   y: number;
    // };
  } {
    return {
      dragParams: {
        marginRight: 0,
        maxX: 0,
        maxY: 0,
      },
      // initial: {
      //   x: 0,
      //   y: 0,
      // },
    };
  },

  computed: {
    ...UIRelationshipMapper.mapState(['relationship']),
    ...UICommonMapper.mapState(['showedMemberPopup']),

    // 絞り込み結果に表示する33位以降のメンバー
    overflowingMembers(): Relationship {
      const members = this._.drop(this.relationship, 33);
      return members;
    },
  },

  watch: {
    showedMemberPopup: {
      handler() {
        this.dragParams.marginRight = this.showedMemberPopup ? 255 : 0;
        this.dragParams.maxX = 0;
      },
    },
  },

  mounted() {
    // window.addEventListener('resize', this.handleResize);
  },

  methods: {
    ...UICommonMapper.mapActions(['hidePopupBackgroundLayer']),

    closePopup() {
      this.$emit('click-close');
    },

    // handleResize() {
    //   this.dragParams.maxX = 0;
    // },

    // onDrag(event: MouseEvent) {
    //   const self = this;
    //   const element = this.$refs.filterResult as HTMLElement;

    //   const deltaY = event.pageY - self.initial.y;
    //   const deltaX = event.pageX - self.initial.x;

    //   if (deltaX >= OffsetLeft && deltaX <= self.dragParams.maxX) {
    //     element.style.left = `${deltaX}px`;
    //   }
    //   if (deltaY >= OffsetTop && deltaY <= self.dragParams.maxY) {
    //     element.style.top = `${deltaY}px`;
    //   }
    // },

    // onDragStart() {
    //   return false;
    // },

    // onMouseDown(event: MouseEvent) {
    //   const self = this;
    //   const element = this.$refs.filterResult as HTMLElement;

    //   self.initial.x = event.pageX - element.offsetLeft;
    //   self.initial.y = event.pageY - element.offsetTop;

    //   if (self.dragParams.maxX === 0) {
    //     self.dragParams.maxX =
    //       window.innerWidth - ClientWidth - OffsetLeft - self.dragParams.marginRight;
    //     self.dragParams.maxY = window.innerHeight - ClientHeight - OffsetTop - SideBarWidth;
    //   }
    // },

    showFilterPopup() {
      this.$emit('click-close');
      this.$emit('click-show-filter-popup');
    },
  },
});
