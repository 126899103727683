























import Vue from 'vue';
import FilterPopup from '@/components/specific/FilterPopup.vue';
import FilterResult from '@/components/specific/FilterResult.vue';
import MemberListPopup from '@/components/specific/MemberListPopup.vue';
import RelationEllipse from '@/components/specific/RelationEllipse.vue';
import Log from '@/resources/plugins/Logger/Log';
import ServiceFactory from '@/services/ui/ServiceFactory';
import store from '@/store';
import { DomainAuth, DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommon, UICommonMapper } from '@/store/modules/ui/common';
import {
  UIMemberFilter,
  UIMemberFilterMapper,
  InitialSearchConditions,
} from '@/store/modules/ui/memberFilter';
import { UIRelationship, UIRelationshipMapper } from '@/store/modules/ui/relationship';
import type { Relationship } from '@/store/modules/ui/relationship';

const SearchService = ServiceFactory.get('search');
const RelationshipService = ServiceFactory.get('relationship');

export default Vue.extend({
  name: 'HomePage',

  components: {
    FilterPopup,
    FilterResult,
    MemberListPopup,
    RelationEllipse,
  },

  data(): {
    showedDialog: {
      [key: string]: boolean;
    };
  } {
    return {
      showedDialog: {
        filterResult: false,
        memberCircleWindow: false,
      },
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UIRelationshipMapper.mapState(['relationship']),
    ...UICommonMapper.mapState([
      'showedFilterPopup',
      'showedGreetingPopup',
      'showedMemberListPopup',
      'showedUserMenu',
    ]),
    ...UIMemberFilterMapper.mapState(['filter', 'order', 'filtered']),
  },

  watch: {
    filter: {
      handler() {
        this.refreshRelationship();
      },
      deep: true,
    },
  },

  async beforeRouteEnter(to, from, next) {
    if (from.name === 'Login' || from.name === 'DownloadApp' || from.name === 'SettingApp') {
      try {
        const domainAuthContext = DomainAuth.context(store);
        const { userAttributes } = domainAuthContext.state;
        const { workspaceId, userId } = userAttributes;

        const uiMemberFilterContext = UIMemberFilter.context(store);
        const { setMemberList, setOrder } = uiMemberFilterContext.actions;
        const { filtered, filter } = uiMemberFilterContext.state;

        const relationContext = UIRelationship.context(store);
        const { setRelationship } = relationContext.actions;

        const commonContext = UICommon.context(store);
        const { setUserList } = commonContext.actions;

        const condition = filtered ? filter : InitialSearchConditions;
        const procedures = [
          RelationshipService.getRelationshipSorted(workspaceId, userId),
          SearchService.memberSearch(condition),
          SearchService.memberSearch(InitialSearchConditions),
        ];

        const [resRelationship, resSearch, resSearchAll] = await Promise.all(procedures);
        setOrder({ order: resRelationship });
        setMemberList({ memberList: resSearch });

        next(() => {
          // 自分を中心（配列0番）にする
          const relationship = [];
          const me = Vue._.find(resSearchAll, { userId });
          relationship.push(me);

          if (!Vue._.isEmpty(resSearch)) {
            // サーチの結果をリレーションシップ順に並べる
            const sortedMembers = resRelationship
              // APIレスポンスなのでanyを許容
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .map((x: any) => resSearch.find((v: any) => v.userId === x.userId))
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .filter((e: any) => e);

            // 自分以外を関係が近い順に並べる
            Vue._.forEach(sortedMembers, (member) => {
              if (member.userId !== userId) {
                relationship.push(member);
              }
            });
          }

          setRelationship({ relationship: Vue._.cloneDeep(relationship) });

          // ユーザー一覧をストアにセット
          const sortedUserList = Vue._.sortBy(resSearchAll, 'kana');
          setUserList({ users: sortedUserList });
        });
      } catch (error: any) {
        Log.error(error);
        const uiCommonContext = UICommon.context(store);
        const { setMessage, setNavigating } = uiCommonContext.actions;
        setMessage({ color: 'error', text: error.message });
        setNavigating({ navigating: false });

        next(false);
      }
    } else {
      next();
    }
  },

  created() {
    if (this.showedGreetingPopup) {
      this.hideAllPopup();
      this.showPopupBackgroundLayer();
      this.showGreetingPopup();
    } else {
      this.hideAllPopup();
    }
  },

  methods: {
    ...UICommonMapper.mapActions([
      'setNavigating',
      'setMessage',
      'clearProfileUserId',
      'showAttendanceBookPopup',
      'hideAllPopup',
      'setProfileUserId',
      'showFilterPopup',
      'hideFilterPopup',
      'showGreetingPopup',
      'showMemberListPopup',
      'hideMemberListPopup',
      'showPopupBackgroundLayer',
      'hidePopupBackgroundLayer',
      'showProfilePopup',
      'hideProfilePopup',
      'showQuickChatPopup',
      'hideQuickChatPopup',
    ]),
    ...UIMemberFilterMapper.mapActions(['setMemberList', 'setOrder']),
    ...UIRelationshipMapper.mapActions(['setRelationship']),

    async applyFilter() {
      const self = this;

      await self.refreshRelationship();
      self.hideAllPopup();

      self.showedDialog.filterResult = self.relationship.length > 33;
    },

    closeFilterPopup() {
      this.hidePopupBackgroundLayer();
      this.hideFilterPopup();
    },

    closeFilterResult() {
      this.showedDialog.filterResult = false;
    },

    closeMemberListPopup() {
      this.hideQuickChatPopup();
      this.hidePopupBackgroundLayer();
      this.hideMemberListPopup();
    },

    openFilterPopup() {
      this.hideAllPopup();
      this.showPopupBackgroundLayer();
      this.showFilterPopup();
    },

    openMemberListPopup() {
      this.hideAllPopup();
      this.showPopupBackgroundLayer();
      this.showMemberListPopup();
    },

    async refreshRelationship() {
      const self = this;
      const { workspaceId, userId } = self.userAttributes;

      const condition = self.filtered ? self.filter : InitialSearchConditions;

      try {
        const procedures = [
          RelationshipService.getRelationshipSorted(workspaceId, userId),
          SearchService.memberSearch(condition),
          SearchService.memberSearch(InitialSearchConditions),
        ];

        const [resRelationship, resSearch, resSearchAll] = await Promise.all(procedures);
        self.setOrder({ order: resRelationship });
        self.setMemberList({ memberList: resSearch });

        // 自分を中心（配列0番）にする
        const relationship: Relationship = [];
        const me = self._.find(resSearchAll, { userId });
        relationship.push(me);

        if (!self._.isEmpty(resSearch)) {
          // サーチの結果をリレーションシップ順に並べる
          const sortedMembers = resRelationship
            // APIレスポンスなのでanyを許容
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((x: any) => resSearch.find((v: any) => v.userId === x.userId))
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .filter((e: any) => e);

          // 自分以外を関係が近い順に並べる
          self._.forEach(sortedMembers, (member) => {
            if (member.userId !== userId) {
              relationship.push(member);
            }
          });
        }

        self.setRelationship({ relationship: self._.cloneDeep(relationship) });
      } catch (error: any) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }
    },
  },
});
